<template>
	<div id="banner-form">
		<ValidationObserver ref="form">
			<form @submit.prevent="applyForm">
				<div class="banner-form__header mb-3">
					<h5 v-if="!isUpdate">{{ $t("NotificationForm.CreateNotification") }}</h5>
				</div>

				<CContainer>
					<CRow>
						<div class="banner-form__container">
							<!-- NOTIFICATION TITLE -->
							<div class="form-group required">
								<label for="name" class="form-group__label col-form-label">{{
									$t("NotificationForm.Title")
								}}</label>
								<div class="form-group__input">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="notification_name"
										name="notification_name"
										rules="required"
									>
										<div :class="[classes]">
											<input
												id="name"
												v-model.trim="notificationForm.title"
												type="text"
												class="form-control"
												:placeholder="$t('NotificationForm.PlaceHolderTitle')"
												:maxlength="nameLength"
											/>
											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
							<!-- END NOTIFICATION TITLE -->

							<!-- NOTIFICATION BODY -->
							<div class="form-group required">
								<label for="body" class="form-group__label col-form-label">{{
									$t("NotificationForm.Body")
								}}</label>
								<div class="form-group__input">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="notification_body"
										name="notification_body"
										rules="required"
									>
										<div :class="[classes]">
											<input
												id="body"
												v-model.trim="notificationForm.body"
												type="text"
												class="form-control"
												:placeholder="$t('NotificationForm.PlaceHolderBody')"
												:maxlength="nameLength"
											/>
											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
							<!-- END NOTIFICATION BODY -->

							<!-- NOTIFICATION USER TYPE -->
							<div class="form-group required">
								<label for="type" class="form-group__label col-form-label">{{
									$t("NotificationForm.Group")
								}}</label>
								<div class="form-group__input">
									<ValidationProvider
										ref="banner-type"
										v-slot="{ classes, errors }"
										name="banner_type"
										rules="required"
									>
										<div :class="[classes]">
											<multiselect
												id="type"
												v-model="notificationForm.groupUser"
												:options="groupType"
												:custom-label="typeGroupLabel"
												:searchable="false"
												:close-on-select="true"
												:show-labels="false"
												:multiple="false"
												placeholder="Chon loại"
												@close="checkValidate('banner-type')"
											>
											</multiselect>

											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
							<!-- END NOTIFICATION USER TYPE -->

							<!-- Find customers -->
							<div v-if="isShowSearchUser()" class="form-group find-customer">
								<label for="find-customer" class="form-group__label">{{
									$t("AppointmentForm.FindCustomers")
								}}</label>
								<div class="form-group__input">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="find_customer"
										name="appointment_find_customer"
									>
										<div :class="[classes]">
											<multiselect
												id="find-customer"
												v-model.trim="findCustomer"
												track-by="id"
												label="fullName"
												:options="customers"
												:custom-label="nameWithPhone"
												:close-on-select="true"
												:show-labels="false"
												:allow-empty="true"
												:searchable="true"
												:internal-search="false"
												:multiple="true"
												:options-limit="Number(totalCustomer)"
												:placeholder="$t('AppointmentSearch.SearchBy')"
												@open="getCustomers()"
												@search-change="asyncFindCustomers"
												@close="resetCustomers"
											>
												<template slot="clear" slot-scope="props">
													<div
														v-show="findCustomer"
														class="multiselect__clear"
														@mousedown.prevent.stop="clearFindCustomer(props.search)"
													></div>
												</template>
												<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
												<template #noResult>{{ $t("DropDown.NoResult") }}</template>
												<template v-if="isAppendLoading(customers)" #afterList>
													<infinite-loading
														:identifier="infiniteCustomerId"
														@infinite="infiniteHandlerCustomer"
													>
														<div slot="no-more"></div>
														<div slot="no-results"></div>
													</infinite-loading>
												</template>
											</multiselect>

											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</div>
							</div>

							<!-- NOTIFICATION LINK -->
							<div class="form-group">
								<label for="link" class="form-group__label col-form-label">{{
									$t("NotificationForm.Link")
								}}</label>
								<div class="form-group__input">
									<ValidationProvider
										ref="banner-link"
										v-slot="{ classes, errors }"
										name="banner_link"
										rules="isUrl"
									>
										<div :class="[classes]">
											<input
												id="link"
												v-model.trim="notificationForm.link"
												type="text"
												class="form-control"
												:placeholder="$t('BannerForm.PlaceHolderLink')"
											/>
											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
							<!-- END NOTIFICATION LINK -->

							<!-- NOTIFICATION ACTION -->
							<div v-if="isShowActionInput()" class="form-group required">
								<label for="action" class="form-group__label col-form-label">{{
									$t("NotificationForm.Action")
								}}</label>
								<div class="form-group__input">
									<ValidationProvider
										ref="action"
										v-slot="{ classes, errors }"
										name="notification_action"
										rules="required"
									>
										<div :class="[classes]">
											<input
												id="action"
												v-model.trim="notificationForm.action"
												type="text"
												class="form-control"
												:placeholder="$t('NotificationForm.PlaceHolderAction')"
											/>
											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
							<!-- END NOTIFICATION ACTION -->

							<!-- NOTIFICATION DETAIL -->
							<div class="form-group">
								<label for="name" class="form-group__label col-form-label">{{
									$t("NotificationForm.ContentDetail")
								}}</label>
								<div class="form-group__input">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="banner-name"
										name="banner_name"
									>
										<div :class="[classes]">
											<textarea
												id="contentDetail"
												v-model.trim="notificationForm.contentDetail"
												type="text"
												class="form-control"
												rows="3"
												:placeholder="$t('NotificationForm.PlaceHolderContentDetail')"
												:maxlength="200"
											>
											</textarea>
											<div class="error-mess">{{ errors[0] }}</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
							<!-- END NOTIFICATION DETAIL -->
						</div>
					</CRow>

					<CRow>
						<CCol>
							<div v-if="isInvalidForm" class="text-center text-danger mb-2"
								>{{ $t("FormError.images") }}
							</div>
							<div class="banner-form__action text-center button-container">
								<CButton class="m-2" v-bind="{ variant: 'outline' }" color="info" type="submit"
									>{{ textSubmit }}
								</CButton>
								<CButton
									v-if="isUpdate"
									class="m-2"
									v-bind="{ variant: 'outline' }"
									color="warning"
									@click="toggleDeleteModal(true)"
									>{{ $t("Button.Delete") }}
								</CButton>
								<CButton
									v-bind="{ variant: 'outline' }"
									color="danger"
									class="m-2"
									@click="cancelForm"
								>
									{{ $t("Button.Exit") }}
								</CButton>
							</div>
						</CCol>
					</CRow>
				</CContainer>
			</form>
		</ValidationObserver>

		<!-- Show modal when change admin status -->
		<CustomModal
			v-if="isShowStatusModal"
			:is-show-submit="false"
			:cancel-color="'outline-custom'"
			:cancel-text="$t('Button.OK')"
			@cancel-modal="confirmStatus"
		>
			<template #content>
				{{ $t("ClinicForm.ConfirmStatus") }}
			</template>
		</CustomModal>

		<!-- Show modal when delete form -->
		<CustomModal
			v-if="isShowDeleteModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="deleteMessage"
			@cancel-modal="toggleDeleteModal(false)"
		>
			<template #content>
				<p>{{ $t("Button.AreYouSure") }}</p>
				<span>{{ $t("BannerForm.DeleteDescription") }}</span>
			</template>
		</CustomModal>

		<!-- Show modal when cancel if clinicForm was changed -->
		<CustomModal
			v-if="isShowDiscardModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="discardChanges"
			@cancel-modal="toggleDiscardModal(false)"
		>
			<template #content>
				<p>{{ $t("Button.DiscardChanges") }}</p>
				<span>{{ $t("Button.DiscardContent") }}</span>
			</template>
		</CustomModal>

		<!-- Show modal when push notification -->
		<CustomModal
			v-if="isShowPushNotiModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="confirmSendNotification"
			@cancel-modal="togglePushNotiModal(false)"
		>
			<template #content>
				<p>{{ $t("NotificationForm.ModalContent") }}</p>
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import { debounce, get, isEqual } from "lodash-es"

import { GET_CUSTOMERS } from "../store/action-types"

import { RESET_CUSTOMERS, SHOW_PUSH_NOTI_MODAL } from "../store/mutation-types"

import { INPUT_LENGTH } from "@/shared/plugins/constants"
import { GROUP_NOTIFICATION_TYPE } from "../../../shared/plugins"
import {
	CHANGE_STATUS_DISCARD_MODAL,
	CHANGE_STATUS_BANNER_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
} from "../store/mutation-types"
import CustomToastr from "@/shared/plugins/toastr"
export default {
	name: "NotificationForm",

	components: {
		InfiniteLoading: () => import("vue-infinite-loading"),
	},

	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},

	component: {},

	data() {
		return {
			notificationForm: {
				title: null,
				body: null,
				contentDetail: null,
				link: null,
				groupUser: null,
				action: null,
				userId: [],
			},
			originForm: {
				title: null,
				body: null,
				contentDetail: null,
				link: null,
				groupUser: null,
				userId: [],
			},
			codeLength: INPUT_LENGTH.CODE,
			nameLength: INPUT_LENGTH.CLINIC_NAME,
			clientDateFormat: process.env.VUE_APP_SERVER_DATE_FORMAT,
			images: [],
			isInvalidForm: false,
			panelFinishedDate: false,
			groupType: [
				GROUP_NOTIFICATION_TYPE.PERSONS,
				GROUP_NOTIFICATION_TYPE.PREP,
				GROUP_NOTIFICATION_TYPE.ALL,
				GROUP_NOTIFICATION_TYPE.ALL_DEVICE,
			],
			findCustomer: null,
			infiniteCustomerId: Number(new Date()),
			limitDropDown: Number(process.env.VUE_APP_LIMIT_LIST),
			selectedCustomer: [],
			isSearchDisabled: false,
			requestBody: null,
		}
	},

	computed: {
		...mapState("notifications", [
			"customers",
			"filtersCustomer",
			"recentnotification",
			"isShowStatusModal",
			"isShowDiscardModal",
			"isShowPushNotiModal",
			"isShowDeleteModal",
			"totalCustomer",
		]),
		...mapState("authentication", ["notifications"]),
		textSubmit: function () {
			return this.isUpdate ? this.$t("Button.SaveChanges") : this.$t("Button.CreateNew")
		},
	},

	watch: {},

	created() {
		this.setUpData()
	},

	mounted() {},

	beforeDestroy() {
		this.bannerForm = {}
	},

	methods: {
		get,
		...mapActions("notifications", { GET_CUSTOMERS }),
		...mapMutations("notifications", {
			CHANGE_STATUS_DISCARD_MODAL,
			CHANGE_STATUS_DELETE_MODAL,
			CHANGE_STATUS_BANNER_MODAL,
			SHOW_PUSH_NOTI_MODAL,
			RESET_CUSTOMERS,
		}),
		getTitleUpdate(data) {
			if (data) {
				return this.typeLabel(data.type) + " " + data.name
			}
		},
		typeGroupLabel(value) {
			return {
				[GROUP_NOTIFICATION_TYPE.ALL]: this.$t("NotificationForm.ALL"),
				[GROUP_NOTIFICATION_TYPE.PREP]: this.$t("NotificationForm.PREP"),
				[GROUP_NOTIFICATION_TYPE.ALL_DEVICE]: this.$t("NotificationForm.ALL_DEVICE"),
				[GROUP_NOTIFICATION_TYPE.PERSONS]: this.$t("NotificationForm.PERSONS"),
			}[value]
		},
		isShowSearchUser() {
			return this.notificationForm.groupUser === GROUP_NOTIFICATION_TYPE.PERSONS
		},
		setUpData() {
			this.notificationForm = {}
		},
		applyForm: async function () {
			const valid = await this.$refs.form.validate()

			if (!valid) {
				return
			}

			if (
				this.notificationForm.groupUser === GROUP_NOTIFICATION_TYPE.PERSONS &&
				(this.findCustomer == null || this.findCustomer.length === 0)
			) {
				CustomToastr.error(this.$t("NotificationForm.Error"))
				return
			}

			let userArray = []
			if (this.findCustomer) {
				this.findCustomer.forEach(cus => {
					userArray.push({
						id: cus.id,
						userId: cus.userId,
						name: cus.fullName,
					})
				})
			}
			if (this.notificationForm.link == null || this.notificationForm.link === "") {
				this.notificationForm.link = null
				this.notificationForm.action = null
			}
			const params = {
				...this.notificationForm,
				userId: userArray,
			}
			this.requestBody = params
			this.togglePushNotiModal(true)
		},
		checkValidate(field) {
			if (this.notificationForm.groupUser) {
				this.isSearchDisabled = true
			} else {
				this.isSearchDisabled = false
			}
			if (this.$refs[field]) this.$refs[field].validate()
		},
		isShowActionInput() {
			return this.notificationForm.link != null && this.notificationForm.link !== ""
		},
		cancelForm() {
			let isEq
			if (this.isUpdate) {
				const dataForm = {}
				for (const key in this.recentBanner) {
					dataForm[key] = this.bannerForm[key]
				}
				isEq = isEqual(dataForm, this.recentBanner)
			} else isEq = isEqual(this.bannerForm, this.originForm)

			if (!isEq) this.toggleDiscardModal(true)
			else this.$emit("cancel-form")
		},
		getCustomers(keyword) {
			this.RESET_CUSTOMERS()
			this.GET_CUSTOMERS({ ...this.filtersCustomer, keyword: keyword, hasUser: true })
		},
		isAppendLoading(array) {
			return array.length >= this.limitDropDown
		},
		nameWithPhone({ fullName, phone }) {
			return `${fullName} - ${phone}`
		},

		asyncFindCustomers: debounce(async function (query) {
			await this.RESET_CUSTOMERS()
			await this.GET_CUSTOMERS({
				...this.filtersCustomer,
				keyword: query.trim(),
			})

			if (this.customers.length >= this.filtersCustomer.limit) this.infiniteCustomerId += 1
		}, process.env.VUE_APP_DEBOUNCE_TIME),

		async infiniteHandlerCustomer($state) {
			this.filtersCustomer.offset = this.customers.length

			const { items } = await this.GET_CUSTOMERS(this.filtersCustomer)
			if (!items.length) {
				$state.complete()
			}
			$state.loaded()
		},
		resetCustomers() {
			//this.RESET_CUSTOMERS()
		},
		clearFindCustomer() {
			//this.findCustomer = null
		},
		togglePushNotiModal(status = false) {
			this.SHOW_PUSH_NOTI_MODAL(status)
		},
		confirmSendNotification() {
			this.SHOW_PUSH_NOTI_MODAL(false)
			this.$emit("apply-form", this.requestBody)
		},
		toggleDeleteModal(status = false) {
			this.CHANGE_STATUS_DELETE_MODAL(status)
		},
		confirmStatus() {
			this.CHANGE_STATUS_BANNER_MODAL(false)
		},
		toggleDiscardModal(status = false) {
			this.CHANGE_STATUS_DISCARD_MODAL(status)
		},
		discardChanges() {
			this.toggleDiscardModal(false)
			this.$emit("cancel-form")
		},
		deleteMessage() {
			this.toggleDeleteModal(false)
		},

		handleOpenChange(panel) {
			this[panel] = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.banner-form {
	&__header {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
	}

	&__container {
		display: grid;
		grid-gap: 10px 20px;
		width: 100%;
		margin-bottom: 15px;
		border-radius: 12px;
	}
}

input[type="text"] {
	height: 35px !important;
}

.form-group {
	display: flex;
	flex-wrap: wrap;
	gap: 2%;
	margin-bottom: 0;

	&[stretch] {
		.form-group__input {
			flex: 0 0 100%;
			max-width: 100%;

			@media (min-width: $xs) {
				flex: 0 0 75%;
				max-width: 100%;
			}
		}
	}

	&__label {
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xs) {
			flex: 0 0 18%;
			max-width: 18%;
		}
	}

	&__input {
		flex: 0 0 70%;
		max-width: 70%;

		@media (min-width: $xs) {
			flex: 0 0 70%;
			max-width: 70%;
		}

		@media (min-width: $s) {
			flex: 0 0 70%;
			max-width: 70%;
		}

		@media (min-width: $ms) {
			flex: 0 0 70%;
			max-width: 70%;
		}
	}
}

.form-group.required > label::after {
	content: "*";
	color: $color-red;
	margin-left: 2px;
}

.group-time {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	margin-bottom: 10px;

	&__text {
		align-self: center;
	}

	&__input {
		width: 75px;
	}

	&__add {
		text-decoration: underline;
		align-self: center;
	}
}

.group-time + .error-mess {
	position: relative;
	top: -8px;
}

/deep/ .multiselect {
	font-size: 14px;

	&__single {
		font-size: 14px;
	}

	&__tag {
		margin-bottom: 0;
	}
}

/deep/ .vue-star-rating {
	padding-top: 1px;
	align-self: start;

	.vue-star-rating-star {
		display: block;
	}
}

.grid {
	@include grid-between;
	grid-template-columns: 1fr;
	gap: 5px;

	.error-mess {
		grid-column: 1 / -1;
	}
}

.button-container {
	place-content: center;
	justify-content: center;
}

.small-text {
	font-size: 12.5px;
}
</style>
